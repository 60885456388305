.head-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.input-file{
    display: flex;
    flex-direction: column;
}
.document-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.reject-container{
    display: flex;
    align-items: center;
}
/* .reject-btn{
    margin: auto;
    border-radius: 5px;
    padding: 4px 12px;
    background: #22ae22;
    border: none;
} */
.reject-btn{
    background-color: #071945;
    color: white;
    border-radius: 20px;
}
.panImg-container{
    display: flex;
    justify-content: center;
    width: 50%;
    height: 40vh;
    margin-top: 5%;
}