.bank-leftSide {
  width: 28%;
  display: flex;
  flex-direction: column;
}

.bank-rightSide {
  width: 72%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.verification-wrapper {
  height: 80vh;
}

.modall-container {
  position: fixed;
  top: 50%;
  width: 40%;
  z-index: 20;
  height: 40vh;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60rem;
}
/* .right-list li{
    list-style-type: none;
    color: blue;
    padding: 0rem 1.5rem;
} */
