.permanent-address{
    overflow-y: scroll;
    height: 70vh;
}
.head-text{
    display: flex;
    justify-content: space-between;
}
.next-btn{
    text-align: end;
}