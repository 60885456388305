.clientImg-container{
    width: 40%;
    
}
.document-area{
    display: flex;
    margin-top: 5%;
}
.client-text p{
    padding: 12px 0px;
}
.line{
    width: 100%;
    margin: auto;
}
.client-text{
    width: 40%;
}
.clientInput-text{
    border: none;
}
.rejectInput-text{
    width: 50%;
}
.error-text {
    color: #ff3333;
  }