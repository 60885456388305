.bank-leftSide{
    width: 28%;
    display: flex;
    flex-direction:column;
}

.bank-rightSide{
    width: 72%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}
.verification-wrapper{
    height:80vh;
}

/* .right-list li{
    list-style-type: none;
    color: blue;
    padding: 0rem 1.5rem;
} */

